const bffKey = process.env.VUE_APP_BFF_API_KEY;

export const bffHeaders =
  process.env.VUE_APP_AMBIENTE === "MARSH"
    ? {
        "x-api-key": bffKey,
        "jwt-variable": sessionStorage.getItem("jwt-variable"),
      }
    : {
        "x-mclick-key": bffKey,
      };
export default {
  bffHeaders,
};
