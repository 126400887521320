var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('div',{staticClass:"d-flex align-center pa-3"},[_vm._t("table-header-left-1"),(_vm.tableHeaderDivider)?_c('v-divider',{staticClass:"mx-3",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":"","small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v(" Filtrar por: ")]),_c('v-card-text',[(
                !!_vm.headers.find(
                  (e) =>
                    e.value === _vm.quotKey.value ||
                    e.value === _vm.proposalKeyVentas.value
                )
              )?_c('v-text-field',{attrs:{"label":"Cotización","placeholder":"Escribe un nro de cotización para filtrar...","dense":""},model:{value:(_vm.filters.cotizacion),callback:function ($$v) {_vm.$set(_vm.filters, "cotizacion", $$v)},expression:"filters.cotizacion"}}):_vm._e(),(
                !!_vm.headers.find(
                  (e) =>
                    e.value === _vm.proposalKey.value ||
                    e.value === _vm.proposalKeyVentas.value
                )
              )?_c('v-text-field',{attrs:{"label":"Propuesta","placeholder":"Escribe un nro de propuesta para filtrar...","dense":""},model:{value:(_vm.filters.propuesta),callback:function ($$v) {_vm.$set(_vm.filters, "propuesta", $$v)},expression:"filters.propuesta"}}):_vm._e(),(!!_vm.headers.find((e) => e.value === _vm.rutKey.value))?_c('v-text-field',{attrs:{"label":"Numero de documento","placeholder":"Escribe un numero de documento para filtrar...","dense":""},model:{value:(_vm.filters.taxid),callback:function ($$v) {_vm.$set(_vm.filters, "taxid", $$v)},expression:"filters.taxid"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Función","placeholder":"Escribe una función para filtrar...","dense":""},model:{value:(_vm.filters.funcion),callback:function ($$v) {_vm.$set(_vm.filters, "funcion", $$v)},expression:"filters.funcion"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","small":""},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" Borrar ")]),_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.filter()}}},[_vm._v(" Filtrar ")])],1)],1)],1),_vm._t("table-header-left-2"),(!_vm.$vuetify.breakpoint.xs)?_c('v-spacer'):_vm._e(),_vm._t("table-header-right"),_c('v-btn',_vm._g(_vm._b({},'v-btn',_vm.bind.refresh,false),_vm.on.refresh),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.refresh))])],1)],2),_c('v-data-table',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"item.creadoEl",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatoFecha")(item.creadoEl))+" ")]}},{key:"item.acciones",fn:function({ item }){return [(
            _vm.query === _vm.queryType &&
            item?.tipoEvento === _vm.eventType &&
            item?.funcion.includes(_vm.functionType)
          )?_c('v-btn',{attrs:{"color":"teal","x-small":"","fab":"","text":"","loading":_vm.loadingEvent},on:{"click":function($event){return _vm.relayTass(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.refreshTass)+" ")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","x-small":"","fab":"","text":""},on:{"click":function($event){return _vm.openForm(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.detail)+" ")])],1),_c('v-btn',{attrs:{"color":"error","x-small":"","fab":"","text":""},on:{"click":function($event){return _vm.deleteItem(item._id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.delete)+" ")])],1)]}}])},'v-data-table',{ ..._vm.bind.table, ..._vm.tableBind },false),_vm.tableOn))],1),_c('confirm-modal',{ref:"confirm"}),_c('ConsultarPlantilla',{ref:"formDialog",attrs:{"item":_vm.selected,"snackbar":_vm.snackbar},on:{"close":function($event){return _vm.closeForm()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }