import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class BitacoraService {
  constructor() {
    this.URL_API = endpoints.manager.bitacoras;
    this.URL_API_RETRANSMITIR = endpoints.manager.retransmitir;
    this.API_HEADER = bffHeaders;
  }

  GET = (params = null) =>
    new Promise((resolve, reject) => {
      axios({
        url: `${this.URL_API}`,
        method: "GET",
        headers: this.API_HEADER,
        params,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  getAllBy = async (query = null, page = 1, limit = 10) =>
    new Promise((resolve, reject) => {
      const url = `${this.URL_API}?page=${page}&limit=${limit}&${query.query}&${
        query.key_numeroCotizacion !== "" || query.key_numeroCotizacion != null
          ? `key_numeroCotizacion=${query?.key_numeroCotizacion}`
          : ""
      }`;
      axios({
        url: url,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  getById = async (id) =>
    new Promise((resolve, reject) => {
      axios({
        url: `${this.URL_API}/${id}`,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  deleteItemById = (id) =>
    new Promise((resolve, reject) => {
      axios({
        url: `${this.URL_API}/${id}`,
        method: "DELETE",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  POST = (data = null) =>
    new Promise((resolve, reject) => {
      axios({
        url: `${this.URL_API_RETRANSMITIR}`,
        method: "POST",
        headers: this.API_HEADER,
        data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
}

export default BitacoraService;
