import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

export const REQUEST_USERS = (
  { commit, getters, rootGetters },
  payload = {}
) => {
  const messages = getters.GetUserMessages,
    paginationKeys = rootGetters.GetPaginationKeys;
  let path = `${endpoints.users}?`;
  // Parametros adicionales
  path += [
    `${paginationKeys.current}=${
      "page" in payload && payload.page ? payload.page : 1
    }`,
    `${paginationKeys.limit}=${
      "limit" in payload && payload.limit ? payload.limit : 10
    }`,
  ].join("&");
  if ("filter" in payload && payload.filter) {
    path += `&${paginationKeys.filter}=${payload.filter}`;
  }

  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      .then((r) => {
        commit("SetUsers", r.data.data.result);
        resolve(r.data.data._result);
      })
      .catch(() => {
        reject(messages.getError);
      });
  });
};

export const CREATE_USER = ({ getters, rootGetters }, payload) => {
  // CREAR UN USUARIO PARA UN CANAL EN ESPECIFICO
  // Payload => Objeto con los valores del formulario de creacion de usuario del canal.
  const path = `${endpoints.negociosGetAll}/usuarios`,
    keys = getters.GetUserKeys,
    messages = getters.GetUserMessages,
    errors = rootGetters.GetErrorMessages;
  // Parametros adicionales
  payload[keys.channel.channel] =
    keys.channel.channel in payload &&
    Array.isArray(payload[keys.channel.channel])
      ? payload[keys.channel.channel]
      : []; // Agregar el listado de canales
  payload[keys.agreement] =
    keys.agreement in payload ? payload[keys.agreement] || [] : []; // Agregar dominio del canal
  payload[keys.request_source] = "Negocios pvdigital"; // Agregar origen de la solicitud
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url: path, data: payload, headers: bffHeaders })
      //.post(path, payload, { headers: bffHeaders })
      .then(() => resolve(messages.create))
      .catch((r) => {
        let response = {
          message: "",
          type: "error",
        };
        try {
          const error = r.response.data;
          response.message =
            errors[error.codigo_accion] || (error?.error ?? errors["4220"]);
          response.type = "warning";
        } catch {
          response.message = messages.createError;
        }
        reject(response);
      });
  });
};

export const UPDATE_USER = ({ getters }, payload) => {
  // ACTUALIZAR UN USUARIO DE UN CANAL EN ESPECIFICO
  // Payload => Objeto con los valores del formulario de usuario y el id del usuario seleccionado
  const keys = getters.GetUserKeys,
    messages = getters.GetUserMessages,
    // path = `${endpoints.channel.g}${payload[keys.id]}${endpoints.channel.user}`,
    path = `${endpoints.negociosGetAll}/${payload[keys.id]}/usuario`,
    user = {};
  // FormData
  user[keys.name] = payload[keys.name] || "";
  user[keys.lastName1] = payload[keys.lastName1] || "";
  user[keys.lastName2] = payload[keys.lastName2] || "";
  user[keys.phone] = payload[keys.phone] || "";
  user[keys.email] = payload[keys.email] || "";
  user[keys.username] = payload[keys.username] || "";
  user[keys.agreement] = payload[keys.agreement] || [];
  user[keys.roles] = payload[keys.roles] || [];
  // user[keys.roles_permisos.key] = payload[keys.roles_permisos.key] || [];
  user[keys.roles_ids] = payload[keys.roles_ids] || [];
  user[keys.modules] = payload[keys.modules] || [];
  user[keys.clientId] = payload[keys.clientId] || [];
  user[keys.clientSecret] = payload[keys.clientSecret] || [];
  user[keys.request_source] =
    payload[keys.request_source] || "Negocios pvdigital";
  user[keys.channel.channel] = payload[keys.channel.channel] || [];
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", url: path, data: user, headers: bffHeaders })
      //.put(path, user, { headers: bffHeaders })
      .then(() => {
        resolve(messages.update);
      })
      .catch(() => {
        reject(messages.updateError);
      });
  });
};

export const REQUEST_USERS_ROLES = (paylod) => {
  let api = `${endpoints.rolesIds}`;

  if (paylod.page && paylod.limit)
    api +=
      "?" +
      [
        `page=${"page" in paylod && paylod.page ? paylod.page : 1}`,
        `limit=${"limit" in paylod && paylod.limit ? paylod.limit : 1}`,
      ].join("&");

  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: api, headers: bffHeaders })
      //.get(api, { headers: bffHeaders })
      .then((response) => {
        const data = response.data.data;
        const roles = data
          .filter((item) => item.es_activo)
          .map((item) => {
            const { _id, nombre } = item;
            const userRoles = {
              id: _id,
              nombre,
            };
            return userRoles;
          });
        resolve(roles);
      })
      .catch((error) => reject(error));
  });
};

export const REQUEST_USER_ROLES = ({ getters }, payload) => {
  const keys = getters.GetUserKeys;
  let api = `${endpoints.userRoles}/${payload[keys.username]}`;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: api, headers: bffHeaders })
      //.get(api, { headers: bffHeaders })
      .then((response) => {
        const data = response.data.data;
        const { roles_ids } = data;
        resolve(roles_ids);
      })
      .catch((error) => reject(error));
  });
};

export const DOWNLOAD_USERS = ({ getters }, payload) => {
  const path = `${endpoints.usersReport}?filtro=${payload.filtro}`;
  const messages = getters.GetUserMessages;

  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
      responseType: "blob",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject(messages.getError);
      });
  });
};
