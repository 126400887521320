import axios from "axios";
import { bffHeaders } from "@/helpers/headers.js";

export const baseHttp = (
  httpData = {
    url: "",
    method: "",
    commit: null,
    commitMethod: "",
    formData: null,
    params: null,
    keys: {
      result: "",
      postResult: "",
    },
    messages: {
      success: "",
      error: "",
    },
    customHeader: null,
  }
) => {
  return new Promise((resolve, reject) => {
    const http = {
      url: httpData.url,
      method: httpData.method,
    };
    http.headers = bffHeaders;
    if (httpData.formData) {
      http.data = httpData.formData;
    }
    if (httpData.params) {
      http.params = httpData.params;
    }
    axios(http)
      .then((response) => {
        let result = response.data.data?.[httpData.keys?.result || "result"]
          ? response.data.data?.[httpData.keys?.result || "result"]
          : httpData.commitMethod === "setCountries"
          ? response?.data?.data
          : [];
        const pagination =
          response.data.data?.pagination ||
          response.data.data?.[httpData.keys?.result || "result"]?.pagination ||
          {};
        if (httpData.keys?.postResult) {
          result = result[httpData.keys?.postResult] || [];
        }
        if (httpData.commit && httpData.commitMethod) {
          httpData.commit(httpData.commitMethod, result);
        }
        resolve({
          result,
          info: httpData.messages?.success || "Completado",
          pagination,
        });
      })
      .catch((error) => {
        const defaultError =
          httpData.messages?.error || "Ops, ha ocurrido un Error.";
        const data = {
          info: new Error(defaultError).toString(),
          result: error.response.data,
        };
        reject(data);
      });
  });
};

export default baseHttp;
