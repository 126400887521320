export const dateKey = {
  text: "Fecha",
  value: "creadoEl",
  sortable: false,
  align: "left",
};

export const appKey = {
  text: "App",
  value: "app",
  sortable: false,
  align: "left",
};

export const functionKey = {
  text: "Funcion",
  value: "funcion",
  sortable: false,
  align: "left",
};

export const proposalKey = {
  text: "Propuesta",
  value: "key.propuesta",
  sortable: false,
  align: "left",
};

export const proposalKeyVentas = {
  text: "Propuesta",
  value: "propuesta",
  sortable: false,
  align: "left",
};

export const eventKey = {
  text: "Evento",
  value: "tipoEvento",
  sortable: false,
  align: "left",
};

export const vigenciaKey = {
  text: "Vigencia",
  value: "key.mesesVigencia",
  sortable: false,
  align: "left",
};

export const companyKey = {
  text: "Compañía",
  value: "key.companiaDescripcion",
  sortable: false,
  align: "left",
};

export const quotKey = {
  text: "Cotización",
  value: "key.numeroCotizacion",
  sortable: false,
  align: "left",
};

export const quotKeyMpay2 = {
  text: "Cotización",
  value: "cotizacion",
  sortable: false,
  align: "left",
};

export const channelKey = {
  text: "Canal",
  value: "key.nombreCanal",
  sortable: false,
  align: "left",
};

export const productKey = {
  text: "Producto",
  value: "key.nombreProducto",
  sortable: false,
  align: "left",
};

export const rutKey = {
  text: "Número Documento",
  value: "key.numeroDocumento",
  sortable: false,
  align: "left",
};

export const corredorKey = {
  text: "Corredora",
  value: "key.idTrxCorredora",
  sortable: false,
  align: "left",
};

export const intentionKey = {
  text: "Intension",
  value: "key.idIntension",
  sortable: false,
  align: "left",
};

export const messageKey = {
  text: "Mensaje",
  value: "mensaje",
  sortable: false,
  align: "left",
};

export const actionKey = {
  text: "",
  value: "acciones",
  align: "right",
  sortable: false,
};

export const generalTableData = [dateKey, eventKey, functionKey];

export const tarifaTableData = [companyKey, quotKey, productKey, vigenciaKey];

export const bitacoraTableData = [
  {
    code: 1,
    name: "Tarifas",
    api: "api-tarifas",
    headers: [...generalTableData, ...tarifaTableData, channelKey, actionKey],
  },
  {
    code: 2,
    name: "Integrador",
    api: "api-integrador",
    headers: [...generalTableData, ...tarifaTableData, actionKey],
  },
  {
    code: 3,
    name: "Tass",
    api: "api-proveedores",
    headers: [...generalTableData, proposalKey, actionKey],
  },
  {
    code: 4,
    name: "API Venta",
    api: "api-venta",
    headers: [...generalTableData, rutKey, actionKey],
  },
  {
    code: 5,
    name: "API Forum",
    api: "mce-api-integracion-forum",
    headers: [...generalTableData, corredorKey, intentionKey, actionKey],
  },
  {
    code: 6,
    name: "Documentos",
    api: "api-documentos",
    headers: [...generalTableData, rutKey, actionKey],
  },
  {
    code: 7,
    name: "API-VENTAS",
    api: "api-ventas",
    headers: [...generalTableData, proposalKeyVentas, actionKey],
  },
  {
    code: 8,
    name: "MPAY-2.0",
    api: "mpay-2.0",
    headers: [...generalTableData, proposalKeyVentas, messageKey, actionKey],
  },
  {
    code: 9,
    name: "SGS",
    api: "SGS",
    headers: [...generalTableData, proposalKeyVentas, actionKey],
  },
  {
    code: 10,
    name: "Qualitas",
    api: "qualitas",
    headers: [...generalTableData, proposalKeyVentas, actionKey],
  },
  {
    code: 11,
    name: "PACIFICO",
    api: "PACIFICO",
    headers: [...generalTableData, proposalKeyVentas, actionKey],
  },
  {
    code: 12,
    name: "PROVEEDORES",
    api: "PROVEEDORES",
    headers: [...generalTableData, proposalKeyVentas, actionKey],
  },
];

export default bitacoraTableData;
